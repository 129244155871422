import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import block_45route_45global from "/usr/src/app/middleware/block-route.global.js";
import check_45country_45global from "/usr/src/app/middleware/check-country.global.js";
import guest_45global from "/usr/src/app/middleware/guest.global.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  block_45route_45global,
  check_45country_45global,
  guest_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/app/middleware/auth.js"),
  "cart-cookie-validation": () => import("/usr/src/app/middleware/cart-cookie-validation.js")
}