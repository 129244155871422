import i18next from "i18next";
import I18NextVue from "i18next-vue";
import { useMainStore } from "../store/index";

export default defineNuxtPlugin((app) => {
  const store = useMainStore();
  const nuxtApp = useNuxtApp();
  const routeData = useRoute();

  const translationDataState = store.translationData;

  i18next.init(translationDataState);

  nuxtApp.vueApp.use(I18NextVue, { i18next });

  // Link generation helper
  const generateLink = (route, routeName = false) => {
    let locale = i18next.language;
    let [country, pathlocale] = routeData.params.country.split("-");

    // Abort if no route or no locale
    if (!route) {
      return {};
    }

    country = country || store.country;
    locale = locale || store.locale;

    if (!country || !locale) {
      return {};
    }

    // If route parameter is a string, check if it's a path or name of route.
    if (typeof route === "string") {
      if (route[0] === "/") {
        // If route parameter is a path, create route object with path.
        route = { path: route };
      } else if (!routeName) {
        route = { path: '/' + route };
      } else {
        // Else use it as route name.
        route = { name: route };
      }
    }

    const localizedRoute = Object.assign({}, route);

    // if route has a path defined but no name, resolve full route using the path
    if (route.path && !route.name) {
      const path = `/${country}-${locale}${route.path}`;

      localizedRoute.path = path;
    } else {
      // otherwise resolve route via the route name
      // Build localized route options
      localizedRoute.name = country + "-" + locale + "/" + route.name;

      const pramsData = localizedRoute;
      if (pramsData && pramsData.params) {
        const params = pramsData.params
        if (params && params["0"] === undefined && params.pathMatch) {
          params["0"] = params.pathMatch;
        }
      }
    }
    // Resolve localized route
    const { fullPath } = nuxtApp.$router.resolve(localizedRoute);

    return fullPath;
  };

  nuxtApp.provide('i18nPath', generateLink);
});