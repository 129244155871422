export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.browser) return false;
  const arr = [
    "country-contact-us",
    "country-work-with-us",
    "country-about-us",
    "country-products-slug",
  ];
  let enabled = false;
  const router = useRouter();
  if (arr.includes(router.currentRoute.value.name) && !enabled) {
    import("aos").then((module) => {
      import("aos/dist/aos.css").then((c) => {
        enabled = true;
        // Initialize AOS
        nuxtApp.AOS = new module.init(); // eslint-disable-line new-cap
      });
    });
  }
  nuxtApp.hook("page:start", () => {
    if (arr.includes(router.currentRoute.value.name) && !enabled) {
      import("aos").then((module) => {
        import("aos/dist/aos.css").then((c) => {
          enabled = true;
          // Initialize AOS
          nuxtApp.AOS = new module.init(); // eslint-disable-line new-cap
        });
      });
    }
  });
});
