export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.browser) {
    import("vue-final-modal").then((module) => {
      const { createVfm } = module;
      const vfm = createVfm();

      nuxtApp.vueApp.use(vfm);
    });
  }
});
