import { useMainStore } from "~/store";
import { useLocaleStore } from "~/store/locales";

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useMainStore();
  const localeStore = useLocaleStore();
  // const isHMR = import.meta.hot ? true : false;
  // In case of HMR, mutation occurs before nuxReady, so previously saved state
  // gets replaced with original state received from server. So, we've to skip HMR.
  // Also nuxtReady event fires for HMR as well, which results multiple registration of
  // any vuex persisted state plugin
  // if (isHMR) {
  //   return;
  // }

  // skip middleware on server
  if (import.meta.server) return;

  // If request path are from index, ignore it
  if (["/", ""].includes(to.path)) {
    return;
  }

  if (!store.country) {
    let message = "This page could not be found (no-country).";
    let status = 404;

    if (store.maintenance) {
      message = "This page its under maintenance, please check again later.";
      status = 503;
    }

    throw createError({
      statusCode: status,
      statusMessage: message,
    });
  }

  if (!store.locale) {
    throw createError({
      statusCode: 404,
      statusMessage: "This page could not be found (no-locale).",
    });
  }
});
